.song-display-properties{
    float: right;
    margin-right: 16px;
    position: fixed;
    right: 0px;
    top: 5em;
}

.button {
    display: block;
    border: none;
    font-size: 18px;
    text-align: center;
    margin-top: 16px;
    background: #ffffff8a;
    border-radius: 32px;
    width: 32px;
    padding: 0px;
}

.button:hover {
    background-color: whitesmoke;
    border-radius: 32px;
    cursor: pointer;
}

.dark .button{
    background: #1212127d;
    color: #eeeeee;
}