.songBook {
    list-style: decimal;
    margin-bottom: 0em;
    padding-bottom: 1em;
}

.songBook li{
    margin-bottom: 0.5em;
}

.songBook li:last-child{
    margin-bottom: 0em;
}

.light .songBook a:link, .light .songBook a:visited, .light .songBook a:hover, .light .songBook a:active{
    color: black;
    text-decoration: none;
}

.light .songBook a:hover{
    color: #66bfbf;
    text-decoration: none;
}

.dark .songBook a:link, .dark .songBook a:visited, .dark .songBook a:hover, .dark .songBook a:active{
    color: #eeeeee;
    text-decoration: none;
}

.dark .songBook a:hover{
    color: #00adb5;
    text-decoration: none;
}


#searchBoxBlock {
    padding-left: 12px;
    padding-top: 12px;
    padding-right: 12px;
}

#searchSongBox {
    width: 100%;
    max-width: 512px;
    box-sizing: border-box;
}