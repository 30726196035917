html {
  height: 100%;
}

.menu {
  margin-bottom: 4px;
}

.menu {
  background-color: #eaf6f6;
}

.body{
  min-height: 100vh;
}

.dark .menu {
  background-color:#393e46
}

.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 12px;  
}

.menu li{
    display: inline;
}

a:link, a:visited, a:active{
  color: #66BFBF;
}

.menu a{
  padding: 12px;
  text-decoration: none;
  text-align: center;
  color: unset;
  text-transform: uppercase;
}

.menu a:hover{
  background-color:#66bfbf;
}

.dark .menu a:hover{
  background-color: #00adb5;
}

a.active {
  font-weight: bold;
}

.dark {
  background-color: #121212;
  background-size: cover;
  color: #eeeeee;
}

.dark a:link, .dark a:visited, .dark a:active{
  color: #eeeeee;
}