.add-song-container{
    padding: 18px;
    max-width: 30em;
    float: left;
    min-width: 12em;
    width: 100%;
    box-sizing: border-box;
}

.input-field {
    width: 100%;
    max-width: 100vh;
    box-sizing: border-box;
}

#song-text-area {
    min-height: 30em;
    height: 75vh;
}

input {
    border: 1px solid #66BFBF;
    border-radius: 4px;
    padding: 12px;
}

input[type="submit"]{
    background-color: #66BFBF;
    color: #EAF6F6;
    cursor: pointer;
}

select {
    border: 1px solid #66BFBF;
    border-radius: 4px;
    padding: 12px;
}

textarea {
    border: 1px solid #66BFBF;
    border-radius: 4px;
}

label {
    display: inline-block;
}

.clear-float {
    clear: both;
}

.next-form-element {
    margin-top: 0.5em;
}

.form-button {
    margin-left: auto;
    margin-right: 0;
    display: block;
}