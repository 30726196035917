.column{
    display: inline;
    position: relative;
    line-height: 2.2;
}

.paragraph {
    margin-bottom: 20px;
}

.chord {
    position: absolute;
    top: -1.4em;
    font-weight: bold;
    display: inline;
}

.chorus{
    margin-left: 0.5em;
    padding-left: 0.5em;
    border-left-width: 1px;
    border-left-style: solid;
    box-sizing: border-box;
}

.song {
    text-align: center;
    overflow-x: clip;
}

.songContent{
    text-align: left;
    display: inline-block;
    padding: 8px;
}

.comment {
    font-style: italic;
}

.lyrics {
    display: inline;
    word-break: break-word;
}